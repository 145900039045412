import { FiltersFormData } from "@/mvcComponents/Commons/Jobs/JobFilterSection/JobFilters/JobFilter.model";

export const getQueryFromFilter = (filters = {}) => {
  let query = {};
  Object.keys(filters)?.forEach((filter) => {
    if (filters[filter]?.length) {
      query[filter] = filters[filter]?.map((ele) => ele.id).join(",");
    }
  });
  return query;
};

export const getQueryFromFilterItem = (filters = []) => {
  let query = {};
  Object.keys(filters)?.forEach((filter) => {
    if (filters[filter]?.length) {
      query[filter] = filters[filter]?.map((ele) => ele.id).join(",");
    }
  });
  return query;
};

export const getFiltersFromQuery = (query, FiltersOptionLists) => {
  let filter = {};
  let formData = FiltersFormData(FiltersOptionLists);

  Object.keys(query).map((key) => {
    if (key !== "keyword" && key !== "companySlug" && formData[key]) {
      filter[key] = { id: query[key] };
    }
  });
  return filter;
};
